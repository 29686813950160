import React, {ReactNode, useEffect, useRef, useState} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {SizesType} from 'types/Sizes'
import {ThemeType} from 'types/Theme'

import {Divider} from '../../Divider'

export type TitleBarVariant = 'default' | 'inverse' | 'floating'

type TitleBarSize = Extract<SizesType, 'md' | 'lg'>
export interface TitleBarProps {
  /** The TitleBar variant, options are default or inverse */
  variant?: TitleBarVariant
  /** Content placed before the center content. It can be any type of React Element */
  startContent?: ReactNode
  /** Content placed after the center content. It can be any type of React Element */
  endContent?: ReactNode
  /** Content of the Title */
  centerContent?: ReactNode
  /** Extra content at the bottom of the TitleBar */
  bottomContent?: ReactNode
  /** Whether the bottomContent (subHeader) should have paddings around */
  bottomContentPadding?: boolean
  /** Determine if a divider is rendered in the bottom of the TitleBar */
  hasDivider?: boolean
  /** Pass through className to allow styles overrides */
  className?: string
  /** Adds equal horizontal margins on both sides */
  centerAligned?: boolean
  /** The startContent is removed and the Title is aligned to the left */
  leftAligned?: boolean
  /** The size of the PopUp header */
  size?: TitleBarSize
}

const HEADER_HEIGHT = 56

const getBackground = (theme: ThemeType, variant: TitleBarVariant) => {
  if (variant === 'inverse') {
    return theme.colors.background.inverse.c950
  } else if (variant === 'floating') {
    return theme.colors.transparent
  }
  return theme.colors.background.neutral.c000
}

export const TitleBarWrapper = styled.div<{variant: TitleBarVariant}>(
  ({theme, variant}) => css`
    width: 100%;
    position: ${variant === 'floating' ? 'absolute' : 'relative'};
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: ${getBackground(theme, variant)};
    min-height: ${HEADER_HEIGHT}px;
    ${variant === 'floating' && `width: 100%;`};
  `
)

export const HeaderWrapper = styled.div<{size: TitleBarSize}>(
  ({theme, size}) => css`
    position: relative;
    width: 100%;
    display: flex;

    align-items: center;
    
    padding: ${
      size === 'lg'
        ? `${theme.layout.spacing.s400}px ${theme.layout.spacing.s250}px;`
        : `${theme.layout.spacing.s300}px ${theme.layout.spacing.s200}px;`
    }
    min-height: ${size === 'lg' ? '68px' : '64px'};
  `
)

export const StartIconWrapper = styled.div<{
  variant: TitleBarVariant
  size: TitleBarSize
}>(
  ({theme, size}) => css`
    position: absolute;
    z-index: 1;
    left: ${size === 'lg'
      ? theme.layout.spacing.s250
      : theme.layout.spacing.s200}px;
    display: flex;
    justify-content: center;
    align-items: center;
  `
)
export const EndIconWrapper = styled.div<{
  variant: TitleBarVariant
  size: TitleBarSize
}>(
  ({theme, size}) => css`
    position: absolute;
    right: ${size === 'lg'
      ? theme.layout.spacing.s250
      : theme.layout.spacing.s200}px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  `
)

export const CenterContentWrapper = styled.div<{
  variant: TitleBarVariant
  startWidth: number
  endWidth: number
  leftAligned: boolean
  size: TitleBarSize
}>(
  ({theme, variant, startWidth, endWidth, leftAligned, size}) => css`
    position: relative;
    width: calc(100% - ${leftAligned ? '0' : startWidth}px - ${endWidth}px);
    margin-left: ${leftAligned ? '0' : startWidth}px;
    margin-right: ${endWidth}px;
    padding: 0px ${theme.layout.spacing.s300}px;
    display: flex;
    flex-grow: 1;
    justify-content: ${leftAligned ? 'flex-start' : 'center'};
    align-items: center;
    text-align: center;
    touch-action: none;
    min-height: ${theme.layout.spacing.s800}px;

    ${size === 'lg'
      ? theme.typography.text.titleL
      : theme.typography.text.titleM};

    color: ${variant === 'inverse'
      ? theme.colors.content.neutral.c000
      : theme.colors.content.neutral.c950};
  `
)

export const SubHeader = styled.div<{
  variant: TitleBarVariant
  bottomContentPadding: boolean
}>(
  ({theme, variant, bottomContentPadding}) => css`
    width: 100%;
    flex-grow: 1;
    text-align: center;
    ${bottomContentPadding &&
    `
      padding: ${theme.layout.spacing.s000}px ${theme.layout.spacing.s500}px
        ${theme.layout.spacing.s300}px ${theme.layout.spacing.s500}px;
    `}
    ${theme.typography.text.titleS};
    color: ${variant === 'inverse'
      ? theme.colors.content.neutral.c000
      : theme.colors.content.neutral.c950};
  `
)

export const TitleBar = ({
  variant = 'default',
  startContent,
  endContent,
  hasDivider = false,
  centerContent,
  bottomContent,
  bottomContentPadding = true,
  centerAligned = true,
  leftAligned = false,
  size = 'md',
  className
}: TitleBarProps) => {
  const [startContentWidth, setStartContentWidth] = useState(0)
  const [endContentWidth, setEndContentWidth] = useState(0)
  const startContentRef = useRef<null | HTMLDivElement>(null)
  const endContentRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if (startContentRef.current) {
      setStartContentWidth(startContentRef.current.offsetWidth)
    }
    if (endContentRef.current) {
      setEndContentWidth(endContentRef.current.offsetWidth)
    }
  }, [])

  const sideContentWidth: number = Math.max(startContentWidth, endContentWidth)
  const startWidth = centerAligned ? sideContentWidth : startContentWidth
  const endWidth = centerAligned ? sideContentWidth : endContentWidth

  return (
    <>
      <TitleBarWrapper variant={variant} className={className}>
        <HeaderWrapper size={size}>
          <CenterContentWrapper
            variant={variant}
            startWidth={startWidth}
            endWidth={endWidth}
            leftAligned={leftAligned}
            size={size}
          >
            {centerContent}
          </CenterContentWrapper>
          {startContent && !leftAligned && (
            <StartIconWrapper
              ref={startContentRef}
              variant={variant}
              className={className}
              size={size}
            >
              {startContent}
            </StartIconWrapper>
          )}
          {endContent && (
            <EndIconWrapper
              ref={endContentRef}
              variant={variant}
              size={size}
              className={className}
            >
              {endContent}
            </EndIconWrapper>
          )}
        </HeaderWrapper>
        {Boolean(bottomContent) && (
          <SubHeader
            variant={variant}
            className={className}
            bottomContentPadding={bottomContentPadding}
          >
            {bottomContent}
          </SubHeader>
        )}
      </TitleBarWrapper>
      {hasDivider && <Divider />}
    </>
  )
}
